@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  background-color: #f7fafc;
}

button:disabled,
button[disabled] {
  opacity: 0.5;
}
